import React from "react";
import PropTypes from "prop-types";
import { MapContext } from "./UnitMap";


export default class Unit extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);


  }

  handleClick(event) {
    if (this.props.onClick && this.props.id === event.unit.id) {
      this.props.onClick(event.unit);
    }
  }

  handleMouseOver(event) {
    if (this.props.onMouseOver && this.props.id === event.unit.id) {
      this.props.onMouseOver(event.unit);
    }
  }

  handleMouseOut(event) {
    if (this.props.onMouseOut && this.props.id === event.unit.id) {
      this.props.onMouseOut(event.unit);
    }
  }

  handleMouseDown(event) {
    if (this.props.onMouseDown && this.props.id === event.unit.id) {
      this.props.onMouseDown(event.unit);
    }
  }

  handleMouseUp(event) {
    if (this.props.onMouseUp && this.props.id === event.unit.id) {
      this.props.onMouseUp(event.unit);
    }
  }

  handleMouseMove(event) {
    if (this.props.onMouseMove && this.props.id === event.unit.id) {
      this.props.onMouseMove(
        event.unit,
        event.originalEvent.clientX,
        event.originalEvent.clientY
      );
    }
  }

  bindEvents(map) {
    if (this.eventsBound) {
      return;
    }

    map.on("unit:click", this.handleClick);
    map.on("unit:mouseover", this.handleMouseOver);
    map.on("unit:mouseout", this.handleMouseOut);
    map.on("unit:mousedown", this.handleMouseDown);
    map.on("unit:mouseup", this.handleMouseUp);
    map.on("unit:mousemove", this.handleMouseMove);

    this.eventsBound = true;
  }

  unbindEvents(map) {
    if (!this.eventsBound) {
      return;
    }

    map.off("unit:click", this.handleClick);
    map.off("unit:mouseover", this.handleMouseOver);
    map.off("unit:mouseout", this.handleMouseOut);
    map.off("unit:mousedown", this.handleMouseDown);
    map.off("unit:mouseup", this.handleMouseUp);
    map.off("unit:mousemove", this.handleMouseMove);

    this.eventsBound = false;
  }

  componentDidMount() {
    if (!this.context.map) {
      return;
    }

    this.bindEvents(this.context.map);
    this.context.units.find(this.props.id).color(this.props.color);
  }

  componentWillUnmount() {
    if (!this.context.map) {
      return;
    }

    this.unbindEvents(this.context.map);
    this.context.units.find(this.props.id).color("#fff");
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.color !== nextProps.color || this.props.id !== nextProps.id
    );
  }

  render() {
    if (!this.context.map) {
      return null;
    }

    let map = this.context.map;
    let units = this.context.units;
    this.bindEvents(map);

    units.find(this.props.id).color(this.props.color);

    return null;
  }
}

Unit.defaultProps = {
  color: "#fff"
};

Unit.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseMove: PropTypes.func
};

Unit.contextType = MapContext;
