export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

export const GET_LOCATION_BY_ID_REQUEST = 'GET_LOCATION_B_REQUESTY_ID';
export const GET_LOCATION_BY_ID_SUCCESS = 'GET_LOCATION_BID_Y_SUCCESS';
export const GET_LOCATION_BY_ID_FAILURE = 'GET_LOCATION_BY_ID_FAILURE';

export const LOCATION_CLICK_EVENT_REQUEST = 'LOCATION_CLICK_EVENT_REQUEST';
export const LOCATION_CLICK_EVENT_SUCCESS = 'LOCATION_CLICK_EVENT_SUCCESS';
export const LOCATION_CLICK_EVENT_FAILURE = 'LOCATION_CLICK_EVENT_FAILURE';

export const CLEAR_PREVIOUS_LOCATION_DATA = 'CLEAR_PREVIOUS_LOCATION_DATA';
