import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const UnitPopOver = ({ unit, x, y }) => {
  const [positionX, setPositionX] = useState(0)
  const [positionY, setPositionY] = useState(0)

  const handleMouseMove = () => {
    const mapElement = document.getElementById('left-container');
    if (mapElement) {
      const offsets = mapElement.getBoundingClientRect()
      var top = offsets.top;
      var left = offsets.left;
    }

    const relativeX = x - left;
    const relativeY = y - top;
    setPositionX(relativeX)
    setPositionY(relativeY)
  };

  useEffect(() => {
    handleMouseMove()
  }, [x, y])


  return (<div className="UnitPopOver" style={{ transform: `translate(${positionX}px, ${positionY}px)` }}>
    <div className="title">{unit.unit_number}</div>
  </div>)
}

UnitPopOver.propTypes = {
  unit: PropTypes.any,
  x: PropTypes.number,
  y: PropTypes.number
};

export default UnitPopOver;
