import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import {
    GET_LOCATION_FAILURE,
    GET_LOCATION_REQUEST,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_BY_ID_FAILURE,
    GET_LOCATION_BY_ID_REQUEST,
    GET_LOCATION_BY_ID_SUCCESS,
    CLEAR_PREVIOUS_LOCATION_DATA,
    LOCATION_CLICK_EVENT_REQUEST,
    LOCATION_CLICK_EVENT_FAILURE,
    LOCATION_CLICK_EVENT_SUCCESS
} from '../constants/location.constant';

// Get Location Request
const getLocationRequest = () => {
    return {
        type: GET_LOCATION_REQUEST,
    };
};

const getLocationSuccess = (locations: AnyIfEmpty<object>) => {
    return {
        type: GET_LOCATION_SUCCESS,
        payload: locations,
    };
};

const getLocationFailure = (locationError: AnyIfEmpty<object>) => {
    return {
        type: GET_LOCATION_FAILURE,
        payload: locationError,
    };
};

const getLocationByIdRequest = () => {
    return {
        type: GET_LOCATION_BY_ID_REQUEST,
    };
};

const getLocationByIdSuccess = (locationByIdData: AnyIfEmpty<object>) => {
    return {
        type: GET_LOCATION_BY_ID_SUCCESS,
        payload: locationByIdData,
    };
};

const getLocationByIdFailure = (locationByIdError: string) => {
    return {
        type: GET_LOCATION_BY_ID_FAILURE,
        payload: locationByIdError,
    };
};

const clearPreviousLocationData = () => {
    return {
        type: CLEAR_PREVIOUS_LOCATION_DATA,
    };
};

export const clearPreviousLocation = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearPreviousLocationData());
    };
};

// Get Location Dispatch
export const getLocation = () => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getLocationRequest());
        return await axios
            .get(`/locations`)
            .then((response) => dispatch(getLocationSuccess(response.data.locations)))
            .catch((error) => dispatch(getLocationFailure(error)));
    };
};

const locationClickEventRequest = () => {
    return {
        type: LOCATION_CLICK_EVENT_REQUEST,
    };
};

const locationClickEventSuccess = (locationByIdData: AnyIfEmpty<object>) => {
    return {
        type: LOCATION_CLICK_EVENT_SUCCESS,
        payload: locationByIdData,
    };
};

const locationClickEventFailure = (locationByIdError: string) => {
    return {
        type: LOCATION_CLICK_EVENT_FAILURE,
        payload: locationByIdError,
    };
};

export const getLocationById = (id: string | undefined | null) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getLocationByIdRequest());
        return axios
            .get(`/locations/${id}`)
            .then((response) => dispatch(getLocationByIdSuccess(response.data)))
            .catch((error) => dispatch(getLocationByIdFailure(error.message)));
    };
};

export const locationClickEvent = (id: string, click_type: string) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(locationClickEventRequest());
        return axios
            .patch(`/locations/click-event-count?locationId=${id}&click_type=${click_type}`)
            .then((response) => dispatch(locationClickEventSuccess(response.data)))
            .catch((error) => dispatch(locationClickEventFailure(error.message)));
    };
}