import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UnitMap, Floor, Unit } from './UnitMap';
import FloorPicker from './FloorPicker';
import UnitPopOver from './UnitPopOver';
import icon404 from '../../images/core/pineapple-404.gif';
import logo from '../../images/core/pineapple-logo-black-text.svg';
import styles from '../../components/not-found/NotFound.module.css';
import '../LocationMap/locationMap.css';

const EngrainMap = ({ unitMapId, unitMapAssetId, appointment }) => {
  const navigate = useNavigate();

  const [unitData, setUnitData] = useState();
  const [unitMap, setUnitMap] = useState();
  const [floorData, setFloorData] = useState();

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();

  const [response, setResponse] = useState(null);
  const [isMapLoad, setIsMapLoad] = useState(false);
  const [timer, setTimer] = useState(true);

  const [floorId, setFloorId] = useState('');
  const [hoveredUnit, setHoveredUnit] = useState();

  const [pos, setPos] = useState({ x: 0, y: 0 });

  const simplifyJson = (inputJson, assetId) => {
    const units = [];
    inputJson.features.forEach((feature) => {
      const unitId = feature.properties.unit_id;
      const floorId = Number(feature.properties.floor_id);
      const unitLabel = feature.properties.unit_label;

      if (feature.geometry.type === 'Point') {
        units.push({
          id: unitId,
          asset_id: assetId,
          floor_id: floorId,
          unit_number: unitLabel,
          base_color: 'rgba(111,124,119,0.53)',
          active_color: '#6f7c77',
        });
      }
    });

    const unitsById = units.reduce((map, unit) => map.set(unit.id, unit), new Map());
    setUnitMap(unitsById);

    return { units };
  };

  const fetchGeojsonData = async (url, assetId) => {
    if (url) {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Failed to fetch geojson data');
        }

        const data = await response.json();
        if (data) {
          const simple = simplifyJson(data, assetId);
          setUnitData(simple?.units);
        }
      } catch (error) {
        console.error('Error fetching geojson data:', error.message);
      }
    }
  };

  function sortFloorsBySortValue(floors) {
    floors.sort((a, b) => a.sort - b.sort);
    return floors;
  }

  const getFloorData = (unitMapAssetId, isMapFound) => {
    if (unitMapAssetId && unitMapAssetId.length > 0) {
      axios
        .get(`https://api.unitmap.com/v1/assets/${unitMapAssetId}/floors`, {
          headers: {
            'API-Key': process.env.REACT_APP_UNIT_MAP_API_KEY,
          },
        })
        .then((floorResponse) => {
          if (floorResponse?.status === 200 && isMapFound) {
            const sortedFloors = sortFloorsBySortValue(floorResponse?.data?.data);
            setFloorData(sortedFloors);
            setFloorId(floorResponse?.data?.data[0]?.id);
          } else {
            navigate('/location-map/coming-soon');
          }
        }).catch((err) => {
          if (isMapFound) {
            navigate('/location-map/coming-soon');
          } else {
            navigate('/appointments');
          }
        });
    } else {
      navigate('/appointments');
    }
  };

  const getMapData = async (propertyId) => {
    try {
      axios.get(`https://api.unitmap.com/v1/maps/${propertyId}`, {
        headers: {
          'API-Key': process.env.REACT_APP_UNIT_MAP_API_KEY,
        },
      }).then((response) => {
        setResponse(response.status);
        if (response?.status === 200) {
          getFloorData(unitMapAssetId, true);
          fetchGeojsonData(response.data.geojson_url, unitMapAssetId);
        }
      }).catch((err) => {
        if (unitMapAssetId && unitMapAssetId.length > 0) {
          getFloorData(unitMapAssetId, false);
        } else {
          navigate('/appointments');
        }
      });
    } catch (err) {
      navigate('/appointments');
    }
  };

  useEffect(() => {
    if (unitMapId && unitMapId.length > 0) {
      getMapData(unitMapId);
    } else if (unitMapAssetId && unitMapAssetId.length > 0) {
      getFloorData(unitMapAssetId, false);
    } else {
      navigate('/appointments');
    }
  }, [unitMapId]);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    setLatitude(crd.latitude);
    setLongitude(crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    const timer = setTimeout(() => setTimer(false), 3000);
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        }
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    return () => { clearTimeout(timer) };
  }, []);

  function updateLatLong(updatedLat, updatedLng, googleMapsUrl) {
    setLatitude(updatedLat);
    setLongitude(updatedLng);
  }

  const applyAppLink = async () => {
    const applicationLink = appointment?.appLink;
    window.open(applicationLink, '_blank', 'noreferrer');
    await axios.get(`appointments/ReadyToApplyClicked/${appointment.appointmentId}`);
  };

  return (
    <>
      {timer && (
        <div className="pageTimer">
          <img src={logo} alt="pineapple logo"></img>
        </div>
      )}
      {response === 200 ? (
        <div className="outletContainer">
          <div className="map-wrapper">
            <div className="left-container" id="left-container">
              <div className='backBtn' >
                <Link to="/" className='btn'>
                  <span className='text'>Back to Appointments</span>
                  <div className='backArrow'></div>
                </Link>
              </div>
              <div className="location-map-inner-container">
                <UnitMap
                  setIsMapLoad={setIsMapLoad}
                  id={unitMapId}
                  apiKey={process.env.REACT_APP_UNIT_MAP_API_KEY}
                  className="unit-map"
                  updateLatLong={updateLatLong}
                  latLong={{ latitude, longitude }}>
                  <Floor id={floorId} />
                  {unitData?.map((unit) => {
                    try {
                      return (
                        <Unit
                          key={unit.id}
                          id={unit.id}
                          color={unit.base_color}
                          onMouseOver={(mapUnit) => {
                            setHoveredUnit(unit);
                          }}
                          onMouseOut={(mapUnit) => {
                            setHoveredUnit(null)
                          }}
                          onMouseMove={(_, x, y) => {
                            setPos({ x, y });
                          }}
                        />
                      );
                    } catch (err) {
                      return null;
                    }
                  })}
                </UnitMap>
                {hoveredUnit && <UnitPopOver unit={hoveredUnit} {...pos} />}

                <FloorPicker floors={floorData} floorId={floorId} onFloorChange={(floorId) => setFloorId(floorId)} />
                {/* {<div className='bottom-btn-container'>

                  {appointment && appointment.leasingAgentNumber && <a className="map-callbtn" href={`tel:+1${appointment?.leasingAgentNumber}`}>
                    Call Agent
                  </a>}
                  {appointment && <div className={`apply-btn`} onClick={applyAppLink}>
                    Apply now
                  </div>}
                </div>} */}
              </div>
            </div>
          </div>
        </div >
      ) : (
        response !== null && (
          <div className={styles.container}>
            <img className={styles.notFoundImage} src={icon404} alt="404" />
            <h2 className={styles.textLg}>404</h2>
            <p>Ooops!...No Data Found.</p>
            <Link to="/" className={styles.btn}>
              Back to Home
            </Link>
          </div>
        )
      )}
    </>
  );
}

export default EngrainMap;