import React, { useEffect, useRef, useState } from 'react';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import iconAddress from '../../../images/icons/icon-address-point.png';
import ReactSlider from 'react-slider';
import { bathrooms, bedrooms, sortBy } from '../../../constants';
import iconDollar from '../../../images/icons/icon-Dollar.svg';
import iconBed from '../../../images/icons/icon-double-bed.svg';
import iconReset from '../../../images/icons/icon-reset.svg';
import iconSquareFeet from '../../../images/icons/icon-square-feet.svg';
import iconFilter from '../../../images/icons/icon-sort.svg';
import banner from '../../../images/no-unit-image.svg';
import iconBath from '../../../images/icons/icon-bath.svg';
import { unitsProps } from '../../../interface/LocationInterface';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import { getUnitsByLocationId } from '../../../redux/unit/actions/unitsByLocationId.action';
import Loader from '../../common/loader/Loader';
import UnitsCard from './UnitsCard/UnitsCard';
import styles from './UnitsDashboard.module.css';
import Popup from '../../common/popup/Popup';
import UnitsFilterPopup from './UnitsFilterPopup';
import { clearPreviousLocation, getLocationById } from '../../../redux/location/actions/location.action';
import Slider from '../../common/slider/Slider';
import { getTime } from '../../../redux/unit/actions/unit.action';
import Faq from '../../tourPortal/faq/Faq';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Lightbox from '../../common/lightbox/Lightbox';
import { SliderWithLightbox } from '../../common/slider-with-lightbox/SliderWithLightBox';
import NoUnits from '../../../images/no-units-found.svg'

const useOutsideAlerter = (
    ref: React.RefObject<HTMLInputElement>,
    setShowDropDown: (arg: string) => void,
    dropdownStatusObj:
        | string
        | {
            bath: boolean;
            bed: boolean;
            square: boolean;
            price: boolean;
            sortBy: boolean;
        },
) => {
    useEffect(() => {
        const handleClickOutside = (event: AnyIfEmpty<object>) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (typeof dropdownStatusObj === 'string') {
                    setShowDropDown(dropdownStatusObj);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

const UnitsDashboard = () => {
    const [open, setIsOpen] = useState(false);
    const locationId = useParams().id;

    const navigate = useNavigate();

    const unitsByLocationId = useSelector((state: IRootState) => state.unitsByLocationId.data);
    const loading = useSelector((state: IRootState) => state.unitsByLocationId.loading);
    // const locationName = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location?.name);
    const locationLoading = useSelector((state: IRootState) => state.locationReducer.locationById.loading);

    const locationdetail = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location);
    const locationObject = useSelector((state: IRootState) => state.locationReducer.locationById.data);

    const dropdownStatusObj = {
        bath: false,
        bed: false,
        square: false,
        price: false,
        sortBy: false,
    };

    const resetObj = {
        minPrice: '',
        maxPrice: '',
        bed: [],
        minSize: '',
        maxSize: '',
        bath: [],
        sortBy: locationdetail?.isPriceListed ? 'price' : 'rooms',
        sortOrder: 'ASC',
    };

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (locationObject && Object.keys(locationObject).length && locationObject.status === false && locationObject.statusCode === 404) {
            dispatch(clearPreviousLocation());
            navigate('/locations');
        }
    }, [locationObject]);

    useEffect(() => {
        if (locationId) {
            dispatch(getLocationById(locationId));
        }
    }, [locationId]);

    const [filters, setFilters]: AnyIfEmpty<object> = useState({
        minPrice: '',
        maxPrice: '',
        bed: [],
        minSize: 0,
        maxSize: 10000,
        bath: [],
        sortBy: 'price',
        sortOrder: 'ASC',
    });

    const [rFilters, setRfilters]: AnyIfEmpty<object> = useState({
        minPrice: '',
        maxPrice: '',
        bed: [],
        minSize: '',
        maxSize: '',
        bath: [],
        sortBy: 'price',
        sortOrder: 'ASC',
    });

    const [showDropDown, setShowDropDown]: AnyIfEmpty<object> = useState(dropdownStatusObj);
    const [startIndex, setStartIndex] = useState(0);
    const [showLightBox, setShowLightBox] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowDropDown, dropdownStatusObj);

    // useEffect(() => {
    //     document.addEventListener('click', handleCarouselClick);
    //     return () => {
    //         document.removeEventListener('click', handleCarouselClick);
    //     };
    // }, []);

    useEffect(() => {
        dispatch(getUnitsByLocationId(locationId, filters));
        setShowDropDown({ ...showDropDown, sortBy: false });
    }, [filters]);

    const clickForLightboxHandler = (index: number) => {
        setStartIndex(index);
        setShowLightBox(true);
        document.body.classList.add('disable-scrolling');
    };

    useEffect(() => {
        if (locationdetail && !locationdetail?.isPriceListed) {
            setFilters({
                minPrice: '',
                maxPrice: '',
                bed: [],
                minSize: 0,
                maxSize: 10000,
                bath: [],
                sortBy: 'rooms',
                sortOrder: 'ASC',
            })
        }
    }, [locationdetail])


    // const handleCarouselClick = (event: any) => {
    //     if (event.target instanceof HTMLElement && event.target.closest('.carousel')) {
    //         const clickedIndex = Number(event.target.getAttribute('data-index'));
    //         clickForLightboxHandler(clickedIndex);
    //     }
    // };

    const handleBedroomChecked = (value: number | string, key: string) => {
        let prev = key === 'bedrooms' ? filters?.bed : filters?.bath;
        const bedroomIndex = prev.indexOf(value);
        if (bedroomIndex !== -1) {
            prev.splice(bedroomIndex, 1);
        } else {
            if (value === '') {
                prev = [];
            } else {
                prev.push(value);
            }
        }
        key === 'bedrooms' ? setFilters({ ...filters, bed: [...prev] }) : setFilters({ ...filters, bath: [...prev] });
    };

    const showDropDownHandler = (dropDownName: string) => {
        const temp = dropdownStatusObj;
        setShowDropDown({ ...temp, [dropDownName]: !showDropDown[dropDownName] });
    };

    const resetHandler = () => {
        setFilters(resetObj);
        setShowDropDown(dropdownStatusObj);
    };

    return (
        <>
            {/* <OuterHeader /> */}
            {/* <Lightbox
                setShowLightBox={setShowLightBox}
                images={locationdetail?.images}
                showLightBox={showLightBox}
                startIndex={startIndex}
            /> */}
            {loading || locationLoading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <Popup
                        open={open}
                        setOpen={setIsOpen}
                        bodyData={<UnitsFilterPopup filters={rFilters} setFilters={setRfilters} open={open} setOpen={setIsOpen} />}
                    />

                    <div className={styles.UnitGreetings}>
                        <div className={styles.titleWrapper}>
                            <h2 className={styles.greetingTitle}>
                                Welcome to <span>{locationdetail?.name} </span>
                            </h2>
                            <span className={styles.sortWrapper} onClick={() => setIsOpen(true)}>
                                <img src={iconFilter} alt="Filter" /> Filter
                            </span>
                        </div>

                        {/* <p className={styles.greetingDesc}>
                            Ready to schedule your self-guided tour? Please begin by selecting the apartment you'd like to see.
                        </p> */}
                    </div>

                    <div className={`${styles.unitCardOuter}`}>
                        <div className={`${styles.unitCard} unitCard`}>
                            <div className="locationCard">
                                {/* {locationdetail?.images ? ( */}
                                <SliderWithLightbox
                                    imgs={locationdetail?.images}
                                    setShowLightBox={setShowLightBox}
                                    showLightBox={showLightBox}
                                    startIndex={startIndex}
                                    onClickHandler={clickForLightboxHandler}
                                />


                                {/* <Slider images={locationdetail?.images} /> */}
                                {/* <Carousel
                                    showArrows={true}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    emulateTouch
                                    preventMovementUntilSwipeScrollTolerance={true}
                                    swipeScrollTolerance={50}>
                                    {locationdetail && locationdetail?.images && !!locationdetail?.images.length ? (
                                        locationdetail?.images.map((img: string, index: number) => (
                                            <div key={index}>
                                                <img src={img} alt="location-image" />
                                            </div>
                                        ))
                                    ) : (
                                        <div>
                                            <img src={banner} alt="" />
                                        </div>
                                    )}
                                </Carousel> */}
                            </div>
                            <div className={styles.locationInfoWrapper}>
                                <div className={styles.locationInfo}>
                                    <p className="address-heading">
                                        <span className={styles.iconAddressWrapper}>
                                            <img src={iconAddress} alt="" />
                                        </span>
                                        <span>{`${locationdetail?.street1 ? locationdetail?.street1 : ''}
                                          ${locationdetail?.street2 ? `,${locationdetail?.street2}` : ''},
                                           ${locationdetail?.city ? locationdetail?.city : ''},
                                            ${locationdetail?.state ? locationdetail?.state : ''}
                                             ${locationdetail?.country ? locationdetail?.country : ''}`}</span>
                                        {/* <span className={styles.timeZone}>{locationdetail?.timeZone}</span> */}
                                    </p>
                                    {/* <h6>Description</h6> */}
                                    <p className="address-text">{locationdetail?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ref={wrapperRef} className={styles.filters}>
                        <div className={styles.filter}>
                            <button className={showDropDown.bed ? `${styles.active}` : ''} onClick={() => showDropDownHandler('bed')}>
                                <img className={styles.iconFilter} src={iconBed} alt="" />
                                Bedrooms
                            </button>
                            <div
                                className={
                                    showDropDown.bed
                                        ? `${styles.filterContainer} ${styles.show}`
                                        : `${styles.filterContainer} ${styles.hide}`
                                }>
                                {!!bedrooms.map(({ name, id, value }, index) => (
                                    <div key={index} className={styles.checkboxContainer}>
                                        <input
                                            type="checkbox"
                                            id={id}
                                            checked={filters.bed.length > 0 ? filters.bed?.includes(value) : value === ''}
                                            onChange={() => handleBedroomChecked(value, 'bedrooms')}
                                        />
                                        <label htmlFor={id}>{name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.filter}>
                            <button className={showDropDown.bath ? `${styles.active}` : ''} onClick={() => showDropDownHandler('bath')}>
                                <img className={styles.iconFilter} src={iconBath} alt="" />
                                Bathrooms
                            </button>
                            <div
                                className={
                                    showDropDown.bath
                                        ? `${styles.filterContainer} ${styles.show}`
                                        : `${styles.filterContainer} ${styles.hide}`
                                }>
                                {bathrooms.map(({ name, id, value }, index) => (
                                    <div key={index} className={styles.checkboxContainer}>
                                        <input
                                            type="checkbox"
                                            id={id}
                                            checked={filters?.bath?.includes(value)}
                                            onChange={() => handleBedroomChecked(value, 'bathrooms')}
                                        />
                                        <label htmlFor={id}>{name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.filter}>
                            <button onClick={() => showDropDownHandler('square')} className={showDropDown.square ? `${styles.active}` : ''}>
                                <img className={styles.iconFilter} src={iconSquareFeet} alt="" />
                                Square footage
                            </button>
                            <div
                                className={
                                    showDropDown.square
                                        ? `${styles.filterContainer} ${styles.show}`
                                        : `${styles.filterContainer} ${styles.hide}`
                                }>
                                <div className={styles.rangeSliderWrapper}>
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="thumb"
                                        trackClassName="track"
                                        min={0}
                                        max={10000}
                                        onAfterChange={(e) => setFilters({ ...filters, minSize: e[0], maxSize: e[1] })}
                                        value={[filters?.minSize || 0, filters?.maxSize || 10000]}
                                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                                        renderThumb={(props, state) => <div {...props}>{`${state.valueNow}`}</div>}
                                        pearling
                                        minDistance={10}
                                    />
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        {!!locationdetail?.isPriceListed && <div className={styles.filter}>
                            <button onClick={() => showDropDownHandler('price')} className={showDropDown.price ? `${styles.active}` : ''}>
                                <img className={styles.iconFilter} src={iconDollar} alt="" />
                                Select Price
                            </button>
                            <div
                                className={
                                    showDropDown.price
                                        ? `${styles.filterContainer} ${styles.show}`
                                        : `${styles.filterContainer} ${styles.hide}`
                                }>
                                <div className={styles.rangeSliderWrapper}>
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="thumb"
                                        trackClassName="track"
                                        min={0}
                                        max={10000}
                                        onAfterChange={(e) => setFilters({ ...filters, minPrice: e[0], maxPrice: e[1] })}
                                        value={[filters?.minPrice || 0, filters?.maxPrice || 10000]}
                                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                                        renderThumb={(props, state) => <div {...props}>{`$${state.valueNow}`}</div>}
                                        pearling
                                        minDistance={10}
                                    />
                                    <p></p>
                                </div>
                            </div>
                        </div>}
                        <div className={styles.filter}>
                            <button className={showDropDown.sortBy ? `${styles.active}` : ''} onClick={() => showDropDownHandler('sortBy')}>
                                Sort By :{' '}
                                {sortBy.find((sort) => sort.sortOrder === filters.sortBy && sort.sort === filters.sortOrder)?.name}
                            </button>
                            <div
                                className={
                                    showDropDown.sortBy
                                        ? `${styles.filterContainer} ${styles.show}`
                                        : `${styles.filterContainer} ${styles.hide}`
                                }>
                                <ul className={styles.filtersList}>
                                    {locationdetail?.isPriceListed ? sortBy.map(({ name, sort, sortOrder }, index) => (
                                        <li
                                            key={index}
                                            className={filters.sortBy === sortOrder && filters.sortOrder === sort ? styles.sortBy : ''}
                                            onClick={() => setFilters({ ...filters, sortBy: sortOrder, sortOrder: sort })}>
                                            {name}
                                        </li>
                                    )) : sortBy.filter(item => item.sortOrder !== 'price').map(({ name, sort, sortOrder }, index) => (
                                        <li
                                            key={index}
                                            className={filters.sortBy === sortOrder && filters.sortOrder === sort ? styles.sortBy : ''}
                                            onClick={() => setFilters({ ...filters, sortBy: sortOrder, sortOrder: sort })}>
                                            {name}
                                        </li>))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div onClick={resetHandler} className={styles.resetFilter}>
                            <img src={iconReset} alt="Reset" />
                            Reset
                        </div>
                    </div>
                    <div className={`${styles.unitsWrapper} unitsWrapper`}>
                        {unitsByLocationId?.units &&
                            unitsByLocationId?.units?.map((unit: unitsProps) => (
                                <div key={unit.unitId} className={styles.cardWrapper}>
                                    <div className={styles.card}>
                                        <UnitsCard locationId={locationId} units={unit} showPrice={locationdetail?.isPriceListed} />
                                    </div>
                                </div>
                            ))}
                        {
                            unitsByLocationId.units?.length === 0 && <div className={styles.noUnits}>
                                <img src={NoUnits} alt="no-units" />
                                <p className={styles.noUnitsFoundText}>No Unit Found</p>
                            </div>
                        }
                    </div>
                    <Faq />
                </div>
            )}
            {/* <OuterFooter /> */}
        </>
    );
};

export default UnitsDashboard;
