import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import iconDelete from '../../images/icons/icon-delete.svg';
import editIcon from '../../images/icons/icon-edit-pencil.svg';
import iconSort from '../../images/icons/icon-sortby.svg';
import { AccessCodeProps, deleteUserAppointment, getUserAppointments, requestAccessCode } from '../../redux/appointment/actions/appointment.action';
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import Loader from '../common/loader/Loader';
import Popup from '../common/popup/Popup';
import { AppointmentsProps } from './appointments.interface';
import styles from './Appointments.module.css';
import DeleteAppointmentPopup from './DeleteAppointmentPopup';
import MapIcon from "../../images/icons/map-icon.svg";
import Webcam from 'react-webcam';
import CloseIcon from '../../images/icon-close.svg';
import CamLayer from '../../images/cam-layer.svg';
import Toast from '../toast/Toast';
import SuccessAnimation from '../icons/SuccessIcon/SuccessAnimation';

export const isAccessible = (appointmentDate: string, appointmentStartTime: string, appointmentEndTime: string) => {
    const startDate = new Date(`${appointmentDate} ${appointmentStartTime}`);
    const endDate = new Date(`${appointmentDate} ${appointmentEndTime}`);
    const currentDate = new Date();



    const startTimeRange = new Date(startDate.getTime() - 15 * 60000);
    const endTimeRange = new Date(endDate.getTime() + 45 * 60000);

    if (currentDate < startTimeRange) {
        return 0;
    } else if (currentDate >= startTimeRange && currentDate <= endTimeRange) {
        return 1;
    } else {
        return -1;
    }
}


const Appointments = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<string>('');
    const [active, setActive] = useState<string>('Desc');
    const [appointmentId, setAppointmentId] = useState<string>('');
    const [showToast, setShowToast] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState({
        appointmentId: "",
        count: 0
    });
    const [accessCode, setAccessCode] = useState("");
    const [showModalPopup, setShowModalPopup] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const { search } = useLocation();

    const appointments: AppointmentsProps[] = useSelector((state: IRootState) => state.appointmentReducer.appointments.data);
    const loading: boolean = useSelector((state: IRootState) => state.appointmentReducer.appointments.loading);


    useEffect(() => {
        if (search) {
            const [, , appointmentId] = search.split('=');
            setAppointmentId(appointmentId);
        } else {
            setAppointmentId('');
        }
    }, [search]);

    useEffect(() => {
        dispatch(getUserAppointments(active === 'Asc' ? 'Asc' : 'Desc'));
    }, [active, dispatch]);

    const handleDelete = () => {
        dispatch(deleteUserAppointment(id, active === 'Asc' ? 'Asc' : 'Desc', { isModified: false }));
        setOpen(false);
    };

    const openModel = (appointmentId: string) => {
        setId(appointmentId);
        setOpen(true);
    };

    const sortHandler = () => {
        active === 'Asc' ? setActive('Desc') : setActive('Asc');
    };

    const navigateForModifyAppointmentHandler = (appointment: AppointmentsProps) => {
        const { locationId, unitId, appointmentDate, appointmentStartTime, appointmentId, appointmentEndTime } = appointment;

        const modifiedAppointmentStartTime = moment(appointmentStartTime, 'HH:mm A').format('HHmm');
        const modifiedAppointmentEndTime = moment(appointmentEndTime, 'HH:mm A').format('HHmm');

        const bookedDate = moment(appointmentDate).format('YYYY-MM-DD');

        navigate(`/location/${locationId}/unit/${unitId}?${bookedDate}_${modifiedAppointmentStartTime}_${modifiedAppointmentEndTime}`, {
            state: { appointmentId, active },
        });
    };

    const [showWebCam, setShowWebCam] = useState(false);
    const [uploadFiles, setUploadFiles] = useState('');
    const [newProfile, setNewProfile] = useState('');
    const [hasMediaAccess, setHasMediaAccess] = useState<boolean | null>(null);
    const [toastMessage, setToastMessage] = useState('');
    const webcamRef = useRef<Webcam>(null);

    const videoConstraints: MediaTrackConstraints | boolean = {
        facingMode: 'user', // or 'environment' for rear camera
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            setNewProfile(imageSrc);
        };
    };

    const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
        const trimmedBase64String = base64String.trim();

        if (!trimmedBase64String.startsWith('data:image/jpeg;base64,')) {
            throw new Error('Invalid base64 string format');
        }
        const byteCharacters = atob(trimmedBase64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        return new File([blob], fileName, { type: contentType });
    }

    const requestCameraPermission = () => {
        const askCameraPermission = async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

        let localStream: MediaStream | null;
        askCameraPermission().then(() => {
        }).then(() => {
            setHasMediaAccess(true);
            localStream?.getTracks().forEach(track => {
                track.stop();
            });
            setShowWebCam(true);
        }).catch((error) => {
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                setHasMediaAccess(false);
                setToastMessage('Camera access denied! Please grant access to your camera to continue.');
            }
        })
    };

    useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setToastMessage('');
                setShowToast(false);
            }, 4000);
        }
    }, [toastMessage]);

    const onSuccess = (accessCode: string) => {
        setIsLoading(false);
        setShowModalPopup(true);
        setAccessCode(accessCode);
        dispatch(getUserAppointments(active === 'Asc' ? 'Asc' : 'Desc'));
    }

    const onFailure = (errorMsg: string) => {
        setToastMessage(errorMsg);
        setShowToast(true);
        setIsLoading(false);
    }

    const submitPhoto = async () => {
        setIsLoading(true);
        const newUserProfile = newProfile;
        setUploadFiles(newUserProfile);
        setNewProfile('');
        setShowWebCam(false);

        const file = await base64ToFile(newUserProfile, 'user', 'image/jpeg');
        const appointmentObj: AccessCodeProps = {
            file: file,
            appointmentId: selectedAppointment.appointmentId,
        }

        dispatch(requestAccessCode(appointmentObj, onSuccess, onFailure));
    }

    const ModalBody = () => {
        return (
            <div className={styles.modalBodyContainer}>
                <div className={styles.modalHeader}>
                    <h3>Upload Selfie</h3>

                    <div className={styles.closeIcon} onClick={() => {
                        setShowWebCam(false);
                        setNewProfile('');
                    }}>
                        <img src={CloseIcon} alt='close-icon' width={20} height={20} />
                    </div>
                </div>
                <div className={styles.modalBodyInnerContainer}>

                    {!newProfile &&
                        <>
                            <div className={styles.camLayer}>
                                <img src={CamLayer} alt='layer' width={684} height={480} />
                            </div>
                            <Webcam
                                audio={false}
                                className={styles.webCamContainer}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                ref={webcamRef}
                                autoFocus
                            />
                        </>
                    }

                    {newProfile && <div className={styles.capturedImage}>
                        <img src={newProfile} alt='' width={640} height={480} />
                    </div>}
                </div>
                <div className={styles.modalFooter}>
                    {newProfile && <button className={styles.retakeBtn} onClick={() => setNewProfile('')}>Recapture Photo</button>}
                    <button className={newProfile ? styles.submitBtn : styles.captureBtn} onClick={newProfile ? submitPhoto : capturePhoto}>{!!newProfile ? 'Submit my picture' : 'Capture photo'}</button>
                </div>
            </div>
        )
    }

    const onRequestAccessKey = (count: number, appointmentId: string) => {
        setSelectedAppointment({
            appointmentId: appointmentId,
            count: count
        });
        if (hasMediaAccess === null) {
            requestCameraPermission();
        } else if (hasMediaAccess) {
            setShowWebCam(true);
        } else {
            setShowToast(true);
            setToastMessage('Camera access denied! Please grant access to your camera to continue.')
        }
    };

    const AccessModalBody = () => {
        return <div className={styles.accessModalBodyContainer}>
            <img src={CloseIcon} alt="close-icon" className={styles.closeIconContainer} onClick={() => setShowModalPopup(false)} />
            <div className={styles.successIcon}>
                <SuccessAnimation />
            </div>
            <p className={styles.successMsg}>Done! We’ve captured and verified your pictured</p>
            <p className={styles.code}>{`Your Access code is : ${accessCode}`}</p>
        </div>
    }

    useEffect(() => {
        if (showModalPopup || showWebCam) {
            window.document.body.style.overflow = "hidden"
        }

        return (() => {
            window.document.body.style.overflow = "auto"
        })
    }, [showModalPopup, showWebCam]);

    const onRequestReturnAccessKey = (appointmentId: string) => {
        navigate(`/tour-completion/${appointmentId}`)
    }


    return (
        <div className={styles.container}>
            <Popup open={open} setOpen={setOpen} bodyData={<DeleteAppointmentPopup setOpen={setOpen} handleDelete={handleDelete} />} />
            {(loading || isLoading) && <Loader />}
            <div className={styles.appointmentTitle}>
                <h1>Appointments</h1>
            </div>

            <div className={styles.appointments}>
                <table className={styles.appointmentTable}>
                    <tbody>
                        <tr className={styles.tableHeader}>

                            <th>Location name</th>
                            <th>Unit Name</th>
                            <th>
                                <span>
                                    Date & Time <img onClick={sortHandler} src={iconSort} alt="Sort" />
                                </span>
                            </th>
                            <th className={styles.appointmentDate}>Access Code</th>
                            <th className={styles.appointmentAction}>Actions</th>
                            {/* <th className={styles.appointmentAction}>Cancel</th> */}
                        </tr>
                        {appointments.length === 0 && (
                            <tr>
                                <td className={styles.noData} colSpan={6}>
                                    No appointment data found
                                </td>
                            </tr>
                        )}
                        {appointments.length > 0 &&
                            appointments.map((appointment: AppointmentsProps) => {
                                const appointmentStatus = isAccessible(appointment?.appointmentDate, appointment?.appointmentStartTime, appointment?.appointmentEndTime);
                                return (<tr
                                    className={appointmentId === appointment.appointmentId ? styles.modifiedAppointment : ''}
                                    key={appointment.appointmentId}>

                                    <td
                                        className={styles.Ul}
                                        onClick={() => window.open(`/location/${appointment.locationId}`, '_blank', 'noreferrer')}>
                                        {appointment.locationName}
                                    </td>
                                    <td>{appointment.unitYardiId ? appointment.unitYardiId : appointment.unitName}</td>
                                    <td>
                                        {moment(appointment.appointmentDate).format('MM-DD-YYYY')}{' '}
                                        {moment(appointment.appointmentStartTime, 'hh:mm A').format('h:mm A')} -{' '}
                                        {moment(appointment.appointmentEndTime, 'hh:mm A').format('h:mm A')}
                                    </td>
                                    {/* <td>{appointmentStatus === 1 ? ((appointment?.accessCodeGenerationCount === 0 && appointment?.entryExitCount === 0) || (appointment?.accessCodeGenerationCount % 2 === 0 && appointment?.entryExitCount % 2 === 0)) ? <p className={styles.btnGreen} onClick={() => onRequestAccessKey(appointment?.entryExitCount, appointment?.appointmentId)}>Request Access Code</p>
                                        : (appointment?.accessCodeGenerationCount % 2 === 1 && appointment?.entryExitCount % 2 === 1) ? <p className={styles.btnGreen} onClick={() => onRequestReturnAccessKey(appointment?.appointmentId)}>Request Return Key Access Code</p> :
                                            appointment?.accessCode ? appointment?.accessCode : null
                                        : appointmentStatus === 0 ? <p className={`${styles.btnGreen} ${styles.disabled}`}>Request Access Code</p> :
                                            appointmentStatus === -1 && appointment?.accessCode ? appointment?.accessCode : <p className={styles.btnRed}>Tour Expired</p>
                                    }
                                    </td> */}
                                    <td>{appointment?.accessCode}</td>
                                    <td>
                                        <div className={styles.actionContainer}>
                                            <div className={styles.actionBox}>
                                                <img
                                                    onClick={() => {
                                                        if (appointmentStatus === 0) {
                                                            navigateForModifyAppointmentHandler(appointment)
                                                        }
                                                    }}
                                                    className={styles.iconEdit}
                                                    src={editIcon}
                                                    alt="Delete"
                                                />
                                            </div>
                                            <div className={styles.actionBox}>
                                                <img
                                                    onClick={() => {
                                                        // if (isAccessible(appointment?.appointmentDate, appointment?.appointmentStartTime, appointment?.appointmentEndTime) === 1 && appointment?.mappedInId) {
                                                        // const queryData: any = {
                                                        //     // unitMapAssetId: appointment?.unitMapAssetId,
                                                        //     // unitMapId: appointment?.unitMapId,
                                                        //     // openViaChatBotPropertyId: appointment?.openViaChatBotPropertyId,
                                                        //     mappedInId: appointment.mappedInId, mappedInFloorId: appointment.mappedInFloorId,
                                                        //     openAIAssistantId: appointment?.openAIAssistantId ? appointment?.openAIAssistantId : null,
                                                        //     openAITourVectorId: appointment?.openAITourVectorId ? appointment?.openAITourVectorId : null,
                                                        //     openAITourFileId: appointment?.openAITourFileId ? appointment?.openAITourFileId : null,
                                                        //     appointmentId: appointment?.appointmentId,
                                                        // };
                                                        // const queryString = Object.keys(queryData)
                                                        //     .map((key) => key + '=' + queryData[key])
                                                        //     .join('&');
                                                        // navigate(`/location-map?${queryString}`)
                                                        navigate(`/map-view/${appointment?.appointmentId}`)
                                                        // }
                                                    }}
                                                    className={styles.iconMap}
                                                    src={MapIcon}
                                                    width={20}
                                                    height={20}
                                                    alt="map"
                                                />
                                            </div>
                                            <div className={styles.actionBox}>
                                                <img
                                                    onClick={() => openModel(appointment.appointmentId)}
                                                    className={styles.iconDelete}
                                                    src={iconDelete}
                                                    alt="Delete"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
            {showWebCam && <Popup open={showWebCam} setOpen={setShowWebCam} bodyData={<ModalBody />} className="custom-webcam-modal" />}
            {!!showModalPopup && <Popup open={showModalPopup}
                setOpen={setShowModalPopup}
                onCloseModal={() => setShowModalPopup(false)}
                bodyData={<AccessModalBody />}
                className="custom-webcam-modal"
                shouldCloseOnOverlayClick={true} />}

            {showToast && <Toast message={toastMessage} type='error' />}
        </div>
    );
};

export default Appointments;
