import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import iconHide from '../../../images/icons/icon-hide.svg';
import iconShow from '../../../images/icons/icon-show.svg';
import { resetPassword } from '../../../redux/auth/actions/resetPassword.action';
import { AppDispatch } from '../../../redux/rootReducer';
import style from './ResetPassword.module.css';

const ResetPassword = () => {
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });

    const { token } = useParams();

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.target as HTMLInputElement;

        setPassword({
            ...password,
            [name]: value,
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (password.password === password.confirmPassword) {
            dispatch(resetPassword({ password: password.password, resetToken: token }, onSuccess));
        }
    };

    const onSuccess = () => {
        navigate('/login');
    };

    return (
        <div className={style.container}>
            <h2 className={style.authTitle}>Reset Your Password</h2>
            <p className={style.authDesc}>You need to recreate password in process to login again. </p>
            <form onClick={submitHandler} className={style.formWrapper}>
                <div className={style.fieldWrapper}>
                    <label>Create Password</label>
                    <div className={style.passwordWrapper}>
                        <input
                            name="password"
                            onChange={onChangeHandler}
                            type={showPass ? 'text' : 'password'}
                            placeholder="Enter your password"
                        />
                        <div onClick={() => setShowPass(!showPass)} className={style.iconToggle}>
                            <img src={!showPass ? iconShow : iconHide} alt="Eye" />
                        </div>
                    </div>
                    {/* <span className={style.invalidCred}>error </span> */}
                </div>
                <div className={style.fieldWrapper}>
                    <label>Confirm Password</label>
                    <div className={style.passwordWrapper}>
                        {/*add className to show red border for error className={styles.error} */}
                        <input
                            name="confirmPassword"
                            onChange={onChangeHandler}
                            type={showConfirmPass ? 'text' : 'password'}
                            placeholder="Enter your password"
                        />
                        <div onClick={() => setShowConfirmPass(!showConfirmPass)} className={style.iconToggle}>
                            <img src={!showConfirmPass ? iconShow : iconHide} alt="Eye" />
                        </div>
                    </div>
                    {/* <span className={style.invalidCred}>error </span> */}
                </div>

                <div className={style.submitWrapper}>
                    <button type="submit">Reset Password</button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
