import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import { RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS } from '../constants/auth.constant';

const resetPasswordRequest = () => {
    return {
        type: RESET_PASSWORD_REQUEST,
    };
};

const resetPasswordSuccess = (data: AnyIfEmpty<object>) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
    };
};

const resetPasswordFailure = (errMsg: AnyIfEmpty<object>) => {
    return {
        type: RESET_PASSWORD_FAILURE,
        payload: errMsg,
    };
};

export const resetPassword = (resetPasswordDetails: AnyIfEmpty<object>, onSuccess: () => void) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(resetPasswordRequest());
        await axios
            .post('/visitor/reset-password', resetPasswordDetails)
            .then((response) => {
                dispatch(resetPasswordSuccess(response.data));
                onSuccess();
            })
            .catch((err) => dispatch(resetPasswordFailure(err)));
    };
};
