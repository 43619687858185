import moment from 'moment';
import React, { useEffect } from 'react';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { locationProps } from '../../interface/LocationInterface';
import { clearPreviousLocation, getLocation } from '../../redux/location/actions/location.action';
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import Loader from '../common/loader/Loader';
import LocationCard from './LocationCard/LocationCard';
import styles from './Locations.module.css';

export interface locationAvilability {
  id: string;
  locker: number;
  startDate: string;
  endDate?: string;
  openTime: string;
  closeTime: string;
  createdAt: Date;
  updatedAt: Date;
}

const Locations = () => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const locations = useSelector((state: IRootState) => state.locationReducer.data);
  const loading = useSelector((state: IRootState) => state.locationReducer.loading);

  useEffect(() => {
    dispatch(getLocation());
  }, [dispatch]);


  const onClickHandler = (location: locationProps) => {
    dispatch(clearPreviousLocation());
    navigate(`/location/${location.id}`, { state: location.name })
  };

  return (
    <>
      {loading && <Loader />}
      <div className={styles.container}>
        <h1 className={styles.title}>Properties</h1>

        <div className={styles.locationCards}>
          {locations &&
            locations.map((location: locationProps) => (
              <div
                onClick={() => onClickHandler(location)}
                key={location.id}
                className={location.isAvailable ? styles.cardWrapper : styles.hidden}>
                <LocationCard location={location} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Locations;
