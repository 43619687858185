import React from "react";
import PropTypes from "prop-types";
import { MapContext } from "./UnitMap";

export default class Floor extends React.PureComponent {
  render() {
    if (!this.context.map) {
      return null;
    }


    let { levels } = this.context;
    let selection = levels.has({ floor: true });
    selection.where({ floor: this.props.id }).show();
    selection.except({ floor: this.props.id }).hide();

    return null;
  }
}

Floor.propTypes = {
  id: PropTypes.string
};

Floor.contextType = MapContext;
