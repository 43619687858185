import React from 'react';
import PropTypes from 'prop-types';

export const MapContext = React.createContext({
  map: null,
  units: null,
  levels: null,
});

export default class UnitMap extends React.Component {
  constructor({ width, height, children }) {
    super({ width, height, children });

    this.elementRef = React.createRef();

    this.state = {
      map: null,
      units: null,
      levels: null,
    };
  }

  componentDidMount() {
    const mapElement = document.getElementById('left-container');
    if (mapElement) {
      const offsets = mapElement.getBoundingClientRect();
      var top = offsets.top;
      var left = offsets.left;
    }
    if (this.map) return false;
    // `unitmap` is the global injected by the UnitMap SDK.
    // eslint-disable-next-line no-undef
    this.map = unitmap(this.elementRef.current, this.props.apiKey, {
      autoScale: 'geo',
      backgroundColor: '#eaeaea',
    });

    const map = this.map;
    const test = this.props.updateLatLong;
    const latLong = this.props.latLong;

    map.load(this.props.id, () => {
      this.props.setIsMapLoad(true);
      this.setState({
        map: map,
        units: map.units(),
        levels: map.levels(),
      });

      if (this.props.latLong) {
        const { latitude, longitude } = this.props.latLong;
        // map.locator().show({latitude, longitude});

        const point = map.geo().toLocal([latitude, longitude]);

        map.locator().show({ latitude, longitude });
        updatePosition(point);

      }

      if (!map.geo().isSupported()) {
        var message = 'The loaded map does not support geolocation.';

        console.error(message);
        return;
      }

      function updatePosition(point) {
        var latLng = map.geo().fromLocal(point);
        map.locator().translate(point).show();

        var googleMapsUrl = `https://www.google.com/maps/@${latLng[0]},${latLng[1]},50m/data=!3m1!1e3`;
        var updatedLat = latLng[0];
        var updatedLng = latLng[1];
        test(updatedLat, updatedLng, googleMapsUrl);
      }
    });
  }

  render() {
    return (
      <div
        className="unit-map-container"
        ref={this.elementRef}
        style={{
          width: '100%',
          height: '100%',
        }}>
        <MapContext.Provider value={this.state}>{this.props.children}</MapContext.Provider>
      </div>
    );
  }
}

UnitMap.defaultProps = {
  width: '100%',
  height: '100%',
};

UnitMap.propTypes = {
  id: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  children: PropTypes.any,
  updateLatLong: PropTypes.any,
  setIsMapLoad: PropTypes.any,
};