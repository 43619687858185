import React, { useContext, useEffect, useState } from 'react';
import styles from './Confirm.module.css';
import bannerImg from '../../../../images/core/appointment-confirmation.png';
import iconCalender from '../../../../images/icons/icon-calendar.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { createAppointment, deleteUserAppointment } from '../../../../redux/appointment/actions/appointment.action';
import ComponentLoader from '../../../common/component-loader/ComponentLoader';
import { AppDispatch, IRootState } from '../../../../redux/rootReducer';
import { AppContext } from '../../../context/AppContect';
import axios from 'axios';
import Popup from '../../../common/popup/Popup';
import Terms from '../../../auth/Terms';

interface availabilities {
  id: string;
  endDate: string;
  openTime: string;
  closeTime: string;
  startDate: string;
  locker?: string | number | undefined;
}

export interface Appointment {
  endTime: string;
  startTime: string;
  reservedDate: string;
  availabilityId: string;
}

export interface Availability {
  id: string;
  endDate: string;
  openTime: string;
  closeTime: string;
  startDate: string;
  locker: number;
}

export interface unit {
  id: string;
  name: string;
  price: number;
  rooms: number;
  baths: number;
  status: number;
  size: number;
  image_url: string;
  locationName: string;
  locationId: string;
  locationTimeZone: string;
  locationStreet1: string;
  locationStreet2: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  locationDescription: string;
  appointments: Appointment[];
  availabilities: Availability[];
  unitYardiId?: string | null;
}

interface props {
  availabilities: availabilities[];
  selectedDate: string;
  unit: unit;
  selectedSlots: string;
  timings?: {
    startTime: string;
    endTime: string;
  };
  lockerInfo: any;
  appointmentId: string | undefined;
}

interface AppointmentData {
  reservedDate: string | Moment;
  start_time: string | Moment;
  end_time: string | Moment;
  availabilityId: string;
  old_start_time: string;
  old_end_time: string;
}

type ActiveData = { active: string };

const Confirm = ({ availabilities, selectedDate, unit, selectedSlots, appointmentId }: props) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { active } = (useLocation().state || '') as ActiveData;
  const { search, pathname } = useLocation();

  const appointmentData = useSelector((state: IRootState) => state.appointmentReducer.appointment.data);
  const loading = useSelector((state: IRootState) => state.appointmentReducer.appointment.loading);

  const [modifiedStartTime, setModifiedStartTime] = useState('');
  const [modifiedEndTime, setModifiedEndTime] = useState('');

  const appContect = useContext(AppContext);
  const [isOpen, setIsTnCOpen] = useState(false);
  const [isAcceptTermsAndConditions, setIsAcceptTermsAndConditions] = useState(true);

  const updateTCCheck = async (status: boolean) => {
    if (status) {
      setIsTnCOpen(false);
      setIsAcceptTermsAndConditions(true);
      appContect.visitor.terms = status
      try {
        const response = await axios.patch('/visitor/update-terms');
        const visitor: string | null = localStorage.getItem('visitor');
        localStorage.setItem('visitor', JSON.stringify({ ...JSON.parse(visitor || ''), terms: true }));
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (!appContect.visitor.terms) {
      setIsTnCOpen(true);
      setIsAcceptTermsAndConditions(false);
    }
  }, [appContect.visitor.terms])

  useEffect(() => {
    getVisitor();
  }, []);


  const getVisitor = async () => {
    try {
      const response = await axios.get('/visitor/me', {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      appContect.visitor = response.data.visitor;
      if (!response.data.visitor.terms) {
        setIsTnCOpen(true);
        setIsAcceptTermsAndConditions(false);
      } else {
        setIsTnCOpen(false);
        setIsAcceptTermsAndConditions(true);
      }
      localStorage.setItem('visitor', JSON.stringify(response.data.visitor));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (search) {
      const [, modifiedStartTime, modifiedEndTime] = search.split('_');
      const bookedStartTime = moment(modifiedStartTime, 'HHmm').format('hh:mm A');
      const bookedEndTime = moment(modifiedEndTime, 'HHmm').format('hh:mm A');
      setModifiedStartTime(bookedStartTime);
      setModifiedEndTime(bookedEndTime);
    } else {
      setModifiedStartTime('');
      setModifiedEndTime('');
    }
  }, [search]);

  const handleConfirm = () => {
    let sortedAvailabilities = unit.availabilities?.sort((a: any, b: any) => a.locker - b.locker);

    const [selectedSlotStartTime, selectedSlotEndTime] = selectedSlots.split('-');

    sortedAvailabilities = sortedAvailabilities?.filter(({ startDate, closeTime, openTime, endDate }) => {
      const availabilityStartDate = moment(`${startDate} ${openTime}`, 'YYYY-MM-DD HH:mm');
      const availabilityEndDate = moment(`${endDate} ${closeTime == '23:59' ? '24:00' : closeTime}`, 'YYYY-MM-DD HH:mm').subtract(
        1,
        'hours',
      );
      const appStartDate = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSlotStartTime}`, 'YYYY-MM-DD HH:mm');
      const appEndDate = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSlotEndTime}`, 'YYYY-MM-DD HH:mm');
      return (
        appStartDate.isBetween(availabilityStartDate, availabilityEndDate, undefined, '[]') &&
        appEndDate.isBetween(availabilityStartDate, availabilityEndDate, undefined, '[]')
      );
    });
    unit.appointments = unit.appointments?.sort((a: any, b: any) => a.locker - b.locker);

    const appointment: AppointmentData = {
      reservedDate: moment(selectedDate).format('YYYY-MM-DD'),
      start_time: moment(selectedSlots, 'h:mm A'),
      end_time: moment(selectedSlots, 'h:mm A').add(1, 'hours'),
      availabilityId: sortedAvailabilities[0]?.id,
      old_start_time: '',
      old_end_time: '',
    };

    const deleteAppointmentDetails: deleteUserAppointment = {
      isModified: appointmentId ? true : false,
      start_time: moment(selectedSlots, 'h:mm A').format('h:mm A'),
      reservedDate: moment(selectedDate).format('YYYY-MM-DD')
    }

    if (unit.appointments?.length) {
      let newAvaiibilityId = '';
      let isNewAvaiibilityFound = false;

      let bookedLocker: any = [];
      unit.appointments.filter(({ reservedDate, startTime, endTime, locker }: any) => {
        const bookingStart = moment(`${reservedDate} ${startTime}`, 'YYYY-MM-DD HH:mm A').subtract(1, 'hours');
        const bookingEnd = moment(`${reservedDate} ${endTime}`, 'YYYY-MM-DD HH:mm A').add(1, 'hours');
        const appStartDate = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSlotStartTime}`, 'YYYY-MM-DD HH:mm');
        const appEndDate = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSlotEndTime}`, 'YYYY-MM-DD HH:mm');
        if (
          appStartDate.isBetween(bookingStart, bookingEnd, undefined, '[]') &&
          appEndDate.isBetween(bookingStart, bookingEnd, undefined, '[]')
        ) {
          bookedLocker.push(locker);
          return true;
        }
      });

      sortedAvailabilities?.forEach(({ id, locker }) => {
        if (isNewAvaiibilityFound || bookedLocker.includes(locker)) {
          return;
        }

        // check any availibility is booked or not
        const bookedAvailibilities = unit.appointments?.filter(
          ({ availabilityId, reservedDate }) => availabilityId === id && reservedDate === appointment.reservedDate,
        );

        if (bookedAvailibilities?.length) {
          const DATE_FORMAT = 'YYYY-MM-DD hh:mm A';

          bookedAvailibilities?.forEach(({ reservedDate, startTime, endTime }) => {
            // it should not be withing booked range
            const newAppointmentStartTime = moment(
              `${appointment.reservedDate} ${selectedSlots.split('-')[0]}`,
              DATE_FORMAT,
            ).format(DATE_FORMAT);

            const newAppointmentEndTime = moment(
              `${appointment.reservedDate} ${selectedSlots.split('-')[1]}`,
              DATE_FORMAT,
            ).format(DATE_FORMAT);

            const bookedStartTime = moment(`${reservedDate} ${startTime}`, DATE_FORMAT).format(DATE_FORMAT);
            const bookedEndTime = moment(`${reservedDate} ${endTime}`, DATE_FORMAT).format(DATE_FORMAT);

            const timeFormattedAppointmentStartTime = moment(newAppointmentStartTime, DATE_FORMAT);
            const timeFormattedAppointmentEndTime = moment(newAppointmentEndTime, DATE_FORMAT);
            const timeFormattedBookedStartTime = moment(bookedStartTime, DATE_FORMAT);
            const timeFormattedBookedEndTime = moment(bookedEndTime, DATE_FORMAT);

            if (
              !moment(timeFormattedAppointmentStartTime).isBetween(
                timeFormattedBookedStartTime,
                timeFormattedBookedEndTime,
                undefined,
                '()',
              ) &&
              !moment(timeFormattedAppointmentEndTime).isBetween(
                timeFormattedBookedStartTime,
                timeFormattedBookedEndTime,
                undefined,
                '()',
              ) &&
              !bookedLocker.includes(locker)
            ) {
              newAvaiibilityId = id;
              isNewAvaiibilityFound = true;
            } else {
              bookedLocker.push(locker);
              isNewAvaiibilityFound = false;
              newAvaiibilityId = '';
            }
          });
        }

        if (!isNewAvaiibilityFound && !bookedLocker.includes(locker)) {
          isNewAvaiibilityFound = true;
          newAvaiibilityId = id;
        }
      });

      if (newAvaiibilityId) {
        appointment.availabilityId = newAvaiibilityId;
      }
    } else {
      let newAvaiibilityId = '';
      let isNewAvaiibilityFound = false;

      let bookedLocker: any = [];

      sortedAvailabilities?.forEach(({ id, locker, startDate, endDate, openTime, closeTime }) => {
        if (isNewAvaiibilityFound || bookedLocker.includes(locker)) {
          return;
        }

        const DATE_FORMAT = 'YYYY-MM-DD hh:mm A';

        // it should not be within booked range
        const newAppointmentStartTime = moment(`${appointment.reservedDate} ${selectedSlots.split('-')[0]}`, DATE_FORMAT).format(
          DATE_FORMAT,
        );

        const newAppointmentEndTime = moment(`${appointment.reservedDate} ${selectedSlots.split('-')[1]}`, DATE_FORMAT).format(
          DATE_FORMAT,
        );

        const bookedStartTime = moment(`${startDate} ${openTime}`, DATE_FORMAT).format(DATE_FORMAT);
        const bookedEndTime = moment(`${endDate} ${closeTime}`, DATE_FORMAT).format(DATE_FORMAT);
        const timeFormattedAppointmentStartTime = moment(newAppointmentStartTime, DATE_FORMAT);
        const timeFormattedAppointmentEndTime = moment(newAppointmentEndTime, DATE_FORMAT);
        const timeFormattedBookedStartTime = moment(bookedStartTime, DATE_FORMAT);
        const timeFormattedBookedEndTime = moment(bookedEndTime, DATE_FORMAT);

        if (
          moment(timeFormattedAppointmentStartTime).isBetween(
            timeFormattedBookedStartTime,
            timeFormattedBookedEndTime,
            undefined,
            '()',
          ) &&
          moment(timeFormattedAppointmentEndTime).isBetween(
            timeFormattedBookedStartTime,
            timeFormattedBookedEndTime,
            undefined,
            '()',
          ) &&
          !bookedLocker.includes(locker)
        ) {
          newAvaiibilityId = id;
          isNewAvaiibilityFound = true;
        } else {
          bookedLocker.push(locker);
          isNewAvaiibilityFound = false;
          newAvaiibilityId = '';
        }

        if (!isNewAvaiibilityFound && !bookedLocker.includes(locker)) {
          isNewAvaiibilityFound = true;
          newAvaiibilityId = id;
        }
      });
      if (newAvaiibilityId) {
        appointment.availabilityId = newAvaiibilityId;
      }
    }
    appointment.start_time = moment(appointment.start_time).format('h:mm A');
    appointment.end_time = moment(appointment.end_time).format('h:mm A');

    if (modifiedStartTime) {
      appointment.old_start_time = modifiedStartTime;
      appointment.old_end_time = modifiedEndTime;
    }

    appointmentId && dispatch(deleteUserAppointment(appointmentId, active === 'Asc' ? 'Asc' : 'Desc', deleteAppointmentDetails));
    dispatch(createAppointment(appointment, navigate));
  };

  return (
    <>
      {loading && <ComponentLoader />}
      {
        isOpen && !isAcceptTermsAndConditions ? <Popup open={isOpen} setOpen={setIsTnCOpen} bodyData={<Terms setOpen={setIsTnCOpen} handleBtnClick={updateTCCheck} />} />
          : null
      }
      {
        isAcceptTermsAndConditions && !isOpen ?
          <div className={styles.dashboardWrapper}>
            <div className={styles.dashboardBanner}>
              <img src={bannerImg} alt="banner" />
            </div>
            <div className={styles.scheduleInfo}>
              {/* {appointmentData && Object.keys(appointmentData).length > 0 && (
                        <h2 className={styles.confirmTitle}>Appointment Confirmed</h2>
                    )} */}
              <p>Success! Your appointment is confirmed. Check your email for appointment details such as directions and access.</p>
              <div className={styles.schedule}>
                <div className={styles.date}>
                  <div className={styles.scheduleIcon}>
                    <img src={iconCalender} alt="Icon Date" />{' '}
                  </div>
                  <span>{unit.locationName}</span>
                  <span>{unit?.unitYardiId ? unit?.unitYardiId : unit?.name}</span>
                  <span>{unit.locationTimeZone}</span>
                  <span>Date: {moment(selectedDate).format('MM-DD-YYYY')}</span>
                  <span>
                    Time: {moment(selectedSlots, 'HH:mm').format('h:mm A')} -{' '}
                    {moment(selectedSlots, 'HH:mm').add(1, 'hours').format('h:mm A')}
                  </span>
                </div>
              </div>
              <div className={styles.btnWrapper}>
                {/* {appointmentData && Object.keys(appointmentData).length > 0 ? (
                            <button onClick={handleOkay}>Okay</button>
                        ) : ( */}
                <button onClick={handleConfirm}>Done</button>
                {/* )} */}
              </div>
            </div>
          </div> : null
      }

    </>
  );
};

export default Confirm;
