import React from "react";
import PropTypes from "prop-types";

export default function FloorPicker({ floors, floorId, onFloorChange }) {
	return (
		<div className="FloorPicker">
			<select
				style={{ width: '100%' }}
				value={floorId}
				onChange={(e) => onFloorChange && onFloorChange(Number(e.target.value))}
			>
				{floors?.map((floor) => (
					<option key={floor.id} value={floor.id}>
						{floor.label}
					</option>
				))}
			</select>
		</div>
	);
}

FloorPicker.propTypes = {
	floors: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	floorId: PropTypes.number,
	onFloorChange: PropTypes.func,
};
